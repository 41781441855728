import React from "react"
import styled from "styled-components"

import backgroundSmallDot from '../images/background-small-dot.png';
import { colors } from '../shared/styles';

const Container = styled.div`
  position : relative;
  display : flex;

  @keyframes cross-rotate {
    100% {
      transform : rotate(90deg);
    }
  }

  @keyframes cross-rotate-back {
    100% {
      transform : rotate(-90deg);
    }
  }

  @keyframes cross-rotate-tick {
    33% {
      transform : rotate(-30deg);
    }

    66% {
      transform : rotate(-60deg);
    }

    100% {
      transform : rotate(-90deg);
    }
  }

  @keyframes cross-rotate-tick-fast {
    12.5% {
      transform : rotate(11.25deg);
    }

    25% {
      transform : rotate(22.5deg);
    }

    37.5% {
      transform : rotate(37.75deg);
    }

    50% {
      transform : rotate(45deg);
    }

    62.5% {
      transform : rotate(56.25deg);
    }

    75% {
      transform : rotate(67.5deg);
    }

    87.5% {
      transform : rotate(78.75deg);
    }

    100% {
      transform : rotate(90deg);
    }
  }

  @keyframes cross-in-out {
    50% {
      transform : rotate(-90deg);
    }

    100% {
      transform : rotate(0deg);
    }
  }

  @keyframes cross-rotate-360 {
    25% {
      transform : rotate(-360deg);
    }

    100% {
      transform : rotate(-360deg);
    }
  }

  & .rotate {
    animation: cross-rotate 1s ease infinite both;
  }

  & .rotate-back {
    animation: cross-rotate-back 1s ease infinite both;
  }

  & .rotate-ticks {
    animation: cross-rotate-tick 3s ease infinite both;
  }

  & .in-out {
    animation: cross-in-out 1s ease infinite both;
  }

  & .rotate-360 {
    animation: cross-rotate-360 3s ease infinite both;
  }

  & .rotate-ticks-fast {
    animation: cross-rotate-tick-fast 1.5s ease infinite both;
  }

  &.collapse-on-hover:hover .dotted-shadow {
    transform : translate(3px, -3px);
  }

  &.collapse-on-hover:hover .content-container {
    transform : translate(-4px, 4px);
  }

  &.collapse-on-hover:hover .gray-shadow {
    transform : translate(-9px, 10px);
  }

  &.collapse-on-hover:active .dotted-shadow {
    transform : translate(6px, -6px);
    transition : all 200ms ease;
  }

  &.collapse-on-hover:active .content-container {
    transform : translate(-6px, 6px);
    transition : all 200ms ease;
  }

  &.collapse-on-hover:active .gray-shadow {
    transform : translate(-16px, 17px);
    transition : all 200ms ease;
  }

`;

const ContentContainer = styled.div`
  flex             : 1;
  border           : 5px solid ${colors.blue};
  padding          : 0px 40px;
  position         : relative;
  background-color : white;

  transition : all 600ms ease;

`;

const DottedShadow = styled.div`
  flex : 1;
  background-image    : url('${backgroundSmallDot}');
  background-repeat   : repeat-xy;
  background-position : bottom left;
  height   : 100%;
  width    : 100%;
  position : absolute;
  left     : -15px;
  bottom   : -15px;

  transition : all 600ms ease;

  &.hidden {
    opacity : 0;
  }
`

const GrayShadow = styled.div`
  flex             : 1;
  background-color : ${colors.lightGrey};
  height           : 100%;
  width            : 100%;
  position         : absolute;
  right            : -12px;
  top              : -13px;

  transition : all 600ms ease;

  &.hidden {
    opacity : 0;
  }
`

const CrossImg = styled.img`
  position : absolute;
  width : 31px;
  height : 31px;

  transition : all 600ms ease;


  &.hidden {
    opacity : 0;
  }
`;


const Cross = ({pink, top, bottom, left, right, style, className = "", ...props}) =>
  <CrossImg
    src={
      pink ? require('../images/pink-cross.svg') : require('../images/black-cross.svg')
    }
    style={{
      ...(top ?    {marginTop    : '-18px', top : 0} : {}),
      ...(bottom ? {marginBottom : '-18px', bottom : 0} : {}),
      ...(left ?   {marginLeft   : '-18px', left : 0} : {}),
      ...(right ?  {marginRight  : '-18px', right : 0} : {}),
      ...style
    }}
    alt=""
    className={className}
    {...props}
    />;

  const Frame = ({children, tl = false, tr = false, bl = false, br = false, style, contentStyle, className, noShadow = false, collapseOnHover = false, ...props}) => {

  const tlPink = tl === 'pink' || tl.color === 'pink';
  const trPink = tr === 'pink' || tr.color === 'pink';
  const blPink = bl === 'pink' || bl.color === 'pink';
  const brPink = br === 'pink' || br.color === 'pink';

  const tlAnimation = tl.animation || '';
  const trAnimation = tr.animation || '';
  const blAnimation = bl.animation || '';
  const brAnimation = br.animation || '';

  return (
    <Container
      style={style}
      className={className + (collapseOnHover ? ' collapse-on-hover' : '')}
    >
      <DottedShadow className={'dotted-shadow' + (noShadow ? ' hidden' : '')} />
      <GrayShadow className={'gray-shadow' + (noShadow ? ' hidden' : '')} />
      <ContentContainer
        style={contentStyle}
        className="content-container"
        {...props}
      >
        {tl && <Cross top left pink={tlPink} className={tlAnimation + (noShadow ? ' hidden' : '')}/>}
        {tr && <Cross top right pink={trPink} className={trAnimation + (noShadow ? ' hidden' : '')}/>}
        {bl && <Cross bottom left pink={blPink} className={blAnimation + (noShadow ? ' hidden' : '')}/>}
        {br && <Cross bottom right pink={brPink} className={brAnimation + (noShadow ? ' hidden' : '')}/>}
        {children}
      </ContentContainer>
    </Container>
  )
}

export default Frame;
