import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Section from '../components/section';
import Frame from '../components/frame';

import { dimensions } from '../shared/styles';
import content from '../data/static-content.js';

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const MainSection = styled(Section).attrs(props => ({
  wrapperStyle : {
    height : '100%'
  }
}))`
  height : 100%;
  padding-top : ${dimensions.headerHeight};
  margin-bottom : 15rem;
  position : relative;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : flex-start;

  & p {font-size : 3rem;}
`;


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <MainSection>
      <Frame tr bl="pink" contentStyle={{paddingBottom : '2rem'}}>
        <h1>{content.error404.title}</h1>
        <p>{content.error404.description}</p>
      </Frame>
    </MainSection>
  </Layout>
)

export default NotFoundPage
